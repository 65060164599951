import { Box, FormControl, IconButton, MenuItem, Stack, Typography } from "@mui/material"
import { ZoomActionButton, StyledSelect, AlignmentBox } from "./ZoomComponent.styles"
import { AlignmentType, ZoomComponentProps } from "./ZoomComponent.types"
import { KeyboardArrowDown as ArrowIcon } from '@mui/icons-material';
import { useCallback, useEffect } from "react";
import { Tooltip } from "../Tooltip";

const HeightAlignIcon = (width: number, height: number) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path d="M21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM21 19.01H3V4.99H21V19.01Z" fill='currentColor' />
    <path d="M7.75781 10.0439L11.0105 10.0439L12.9904 10.0439L16.2431 10.0439L12.0005 5.8013L7.75781 10.0439Z" fill="currentColor" />
    <path d="M7.75781 14.0352L11.0105 14.0352L12.9904 14.0352L16.2431 14.0352L12.0005 18.2778L7.75781 14.0352Z" fill="currentColor" />
  </svg>
)

const WidthAlignIcon = (width: number, height: number) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path d="M21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM21 19.01H3V4.99H21V19.01Z" fill='currentColor' />
    <path d="M15.8184 7.75781L15.8184 11.0105L15.8184 12.9904L15.8184 16.2431L20.061 12.0005L15.8184 7.75781Z" fill="currentColor" />
    <path d="M8.20508 7.75781L8.20508 11.0105L8.20508 12.9904L8.20508 16.2431L3.96244 12.0005L8.20508 7.75781Z" fill="currentColor" />
  </svg>
)

export const ZoomComponent = ({
  zoom,
  widthAlignment,
  step = 10,
  minZoom = 10,
  maxZoom = 400,
  rootContainerRef,
  onChangeZoom,
  onChangeAlignment
}: ZoomComponentProps) => {

  const handleChangeZoom = (value: number) => {
    onChangeZoom(value)
    /*     if (typeof value === 'number') {
          onChangeZoom(value)
        } else {
          onChangeAlignment(value)
        } */
  }

  const increaseZoom = () => {
    onChangeZoom(Math.min(zoom + step, maxZoom))
  }

  const decreaseZoom = () => {
    onChangeZoom(Math.max(zoom - step, minZoom))
  }

  const getSelectContainer = () => {
    return document.fullscreenElement || document.body
  }

  const handleWheelZoom = useCallback(
    (event: WheelEvent) => {
      if (event.ctrlKey) {
        event.preventDefault()
        if (event.deltaY < 0) {
          onChangeZoom(Math.min(zoom + step, maxZoom))
        } else {
          onChangeZoom(Math.max(zoom - step, minZoom))
        }
      }
    },
    [onChangeZoom, zoom]
  )

  useEffect(() => {
    const container = rootContainerRef.current

    if (container) {
      container.addEventListener('wheel', handleWheelZoom, { passive: false })

      return () => {
        container.removeEventListener('wheel', handleWheelZoom)
      };
    }
  }, [handleWheelZoom])

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <ZoomActionButton disableRipple onClick={decreaseZoom}>-</ZoomActionButton>
      <FormControl sx={{ minWidth: 87, }} size="small" >
        <StyledSelect
          value={zoom}
          renderValue={(value) =>
            /* value !== 100 ? */ (
              <Typography fontSize={14}>{value as number}%</Typography>
            ) /* : (
              <Box display='inline-flex' margin='auto' color='#2B3648'>
                {widthAlignment ? WidthAlignIcon(20, 20) : HeightAlignIcon(20, 20)}
              </Box>
            ) */
          }
          onChange={(e) => handleChangeZoom(e.target.value as number)}
          IconComponent={ArrowIcon}
          MenuProps={{
            container: getSelectContainer(),
            PaperProps: {
              style: {
                maxHeight: 150,
                zIndex: 1302
              },
            },
          }}
        >
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={100}>100%</MenuItem>
          <MenuItem value={150}>150%</MenuItem>
          <MenuItem value={200}>200%</MenuItem>
          <MenuItem value={250}>250%</MenuItem>
          <MenuItem value={300}>300%</MenuItem>
          <MenuItem value={350}>350%</MenuItem>
          <MenuItem value={400}>400%</MenuItem>
        </StyledSelect>
        <AlignmentBox>
        <Tooltip title="По ширине страницы">
          <IconButton onClick={() => onChangeAlignment("width")} sx={{ padding: '2px', color: widthAlignment === 'width' ? '#0044B4' : '#5C6E8C' }}>
            {WidthAlignIcon(18, 18)}
          </IconButton>
        </Tooltip>
        <Tooltip title="По высоте страницы">
          <IconButton onClick={() => onChangeAlignment("height")} sx={{ padding: '2px', color: widthAlignment === 'height' ? '#0044B4' : '#5C6E8C'}}>
            {HeightAlignIcon(18, 18)}
          </IconButton>
        </Tooltip>
        <Tooltip title="По оригинальному размеру">
          <IconButton onClick={() => onChangeAlignment("original")} sx={{ padding: '2px', color: widthAlignment === 'original' ? '#0044B4' : '#5C6E8C'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width='18' height='18' viewBox="0 0 24 24" fill="none">
              <path d="M21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM21 19.01H3V4.99H21V19.01Z" fill='currentColor' />
            </svg>
          </IconButton>
        </Tooltip>
      </AlignmentBox>
      </FormControl>
      <ZoomActionButton disableRipple onClick={increaseZoom}>+</ZoomActionButton>

    </Stack>
  )
}