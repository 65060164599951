import { Select, Stack } from "@mui/material";
import styled from "styled-components";
import { ActionButton } from "../../PragmaPdfViewer.styles";

export const ZoomActionButton = styled(ActionButton)`
  padding: 0;
  font-size: 16px;
  font-weight: 600;
`
export const StyledSelect = styled(Select)`
  border-radius: 8px;
  height: /* 34px; */ 23px;

  & .MuiSelect-select {
    padding: 5px 12px 5px 16px;
    display: flex;
    align-items: center;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #2B36481F;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: #2b36488c;
  }

  & .MuiSvgIcon-root {
    font-size: 20px !important;
  }
`
export const AlignmentBox = styled(Stack)`
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background-color: #1976D214;
  height: /* 34px; */ 23px;
  justify-content: space-evenly;
  margin-top: 2px;
`